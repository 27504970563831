<template>
  <div class="flex flex-col h-screen">
    <header class="no_print w-full h-14 px-4 border-b bg-white flex items-center flex-shrink-0">
      <div class="flex items-center justify-between h-full w-full">
        <div class="flex items-center space-x-2 h-full relative flex-grow">
          <vs-button class="exitBtn" @click="close" border>
            <svg-icon icon-class="arrow_left" className="primary xs"></svg-icon>
          </vs-button>
          <div class="exitSpan">
            <span>離開</span>
          </div>
          <h1 class="themeTitle text-limit-1">列印揀貨單</h1>
        </div>
        <vs-button success size="large" @click="print">列印</vs-button>
      </div>
    </header>

    <main>
      <nav class="nav no_print" :style="{ transform: isSlipMenuOpen ? 'translateX(0)' : '' }">
        <header class="flex items-center justify-between px-4 lg:hidden sticky top-0 bg-white border-b z-10" style="min-height: 56px">
          <p class="font-medium text-base text-gray-text_dark">批次列印揀貨單</p>
          <svg-icon @click="isSlipMenuOpen = false" icon-class="cancel" className="gray-dark"></svg-icon>
        </header>

        <section class="nav_body">
          <ul class="flex flex-col items-start space-y-3">
            <li
              v-for="slip in allSlips"
              :key="slip"
              class="checkbox_container"
              :class="[isActive(slip) ? 'border-success' : 'border-transparent']"
            >
              <vs-checkbox success v-model="selectedSlips" class="px-4 py-2 w-full" :val="slip">{{ slip }}</vs-checkbox>
            </li>
          </ul>
        </section>
      </nav>

      <section class="slip_container">
        <!-- 顯示欄位設定 -->
        <div class="flex justify-center no_print">
          <ul class="flex space-x-4">
            <li
              v-for="(value, name, index) in isShow"
              :key="index"
              class="checkbox_isShow_container"
              :class="[value.status ? 'border-success' : 'border-transparent']"
            >
              <vs-checkbox success v-model="value.status" class="px-4 py-2">{{ value.label }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <!-- 條碼類型 -->
        <div v-if="isShow.barcode.status" class="flex flex-col items-center justify-center no_print">
          <p class="text-sm mb-4">條碼類型</p>
          <ul class="flex flex-wrap -mt-4 -mr-4">
            <li
              v-for="(type, index) in barCodeFormats"
              :key="index"
              class="checkbox_isShow_container mr-4 mt-4"
              :class="[barCodeFormat === type.value ? 'border-success' : 'border-transparent']"
            >
              <vs-radio success v-model="barCodeFormat" :val="type.value" class="px-4 py-2" style="height: 47.5px">{{
                type.label
              }}</vs-radio>
            </li>
          </ul>
        </div>

        <!-- 揀貨單 -->
        <div class="slip_print no_break" v-for="slip in selectedSlips" :key="slip">
          <!-- 商店名稱或logo -->
          <!-- <section class="flex items-center justify-center">
            <img style="max-width: 200px" src="https://picsum.photos/300/200/?random=10" />
            <h1>商店名稱</h1>
          </section> -->

          <section class="flex justify-between">
            <section>
              <h1>揀貨單</h1>
              <p>訂單編號: LS22072810002</p>
              <p>訂單日期: 2022-07-28 20:24:09</p>
              <p>運送方式: 宅配</p>
            </section>

            <section class="flex items-center justify-center">
              <img style="max-width: 200px" src="https://picsum.photos/300/200/?random=10" />
              <!-- <h1>商店名稱</h1> -->
            </section>
          </section>

          <!-- 購買人和收件人 -->
          <section class="grid grid-cols-2 gap-10">
            <div>
              <h2>購買人</h2>
              <p>張子謙</p>
              <p>+886920818696</p>
              <p>abc3675878@gmail.com</p>
            </div>
            <div>
              <h2>收件人</h2>
              <p>張子謙</p>
              <p>樹林八街 30-1號</p>
              <p>桃園市 桃園區 330</p>
              <p>台灣 Taiwan</p>
              <p>+886920818696</p>
              <p>abc3675878@gmail.com</p>
            </div>
          </section>

          <!-- 商品列表 -->
          <section>
            <table>
              <thead>
                <tr>
                  <th style="width: 350px" class="text-left">品項</th>
                  <th>數量</th>
                  <th v-if="isShow.sku.status">貨號</th>
                  <th v-if="isShow.barcode.status">條碼</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-border">
                <tr v-for="item in 4" :key="item" class="space-x-4">
                  <td class="flex items-center space-x-2 text-left">
                    <div class="w-14 h-14 overflow-hidden flex-shrink-0">
                      <img class="object-cover" src="https://picsum.photos/100/100/?random=10" />
                    </div>
                    <div>
                      <p>NO.561 FACE MILD SOAP</p>
                      <p>AAA / BBB / CCC</p>
                    </div>
                  </td>
                  <td style="width: 70px">x1</td>
                  <td v-if="isShow.sku.status">CQ234214</td>
                  <td v-if="isShow.barcode.status">
                    <VueBarcode
                      class="flex justify-end"
                      value="thisIsBarCode"
                      :format="barCodeFormat"
                      :width="1"
                      :height="30"
                      :fontSize="12"
                      :flat="true"
                      :displayValue="true"
                      :margin="0"
                    >
                      <span class="text-danger">此品項無條碼資料或條碼格式不符</span>
                    </VueBarcode>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <!-- 訂單備註 -->
          <section v-if="isShow.note.status" class="no_break">
            <h2>備註</h2>
            <p>備註</p>
          </section>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
export default {
  name: 'packing_slip',
  components: {
    VueBarcode,
  },
  data() {
    return {
      isSlipMenuOpen: false,
      selectedSlips: [], // 選中的
      allSlips: [], // 批次編輯的揀貨單
      isShow: {
        sku: {
          label: '顯示貨號',
          status: true,
        },
        barcode: {
          label: '顯示條碼',
          status: false,
        },
        note: {
          label: '顯示備註',
          status: true,
        },
      },
      barCodeFormat: 'CODE128',
      barCodeFormats: [
        {
          label: 'CODE 128',
          value: 'CODE128',
        },
        {
          label: 'CODE 39',
          value: 'CODE39',
        },
        {
          label: 'EAN-13',
          value: 'EAN13',
        },
        {
          label: 'EAN-8',
          value: 'EAN8',
        },
        {
          label: 'UPC',
          value: 'UPC',
        },
        {
          label: 'ITF-14',
          value: 'ITF14',
        },
      ],
    }
  },
  created() {
    console.log(this.$route.query.order)
    let query = this.$route.query.order
    this.allSlips = query.split(',')
    this.selectedSlips = query.split(',')
  },
  methods: {
    isActive(item) {
      return this.selectedSlips.includes(item)
    },
    close() {
      window.close()
    },
    print() {
      // 先儲存設定再列印
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  @apply w-full flex  bg-gray-background;
  height: calc(100% - 56px);
}
.nav {
  @apply flex flex-col text-sm overflow-hidden transform translate-x-0 border-r w-80 fixed text-gray-text_light bg-gray-background;
  height: calc(100% - 56px);
  @media (max-width: 1024px) {
    // transform: translateX(-100%);
    @apply transition-transform duration-500 ease-in-out h-full left-0 top-0 z-500 -translate-x-full;
  }

  .nav_body {
    @apply space-y-4 h-full overflow-y-auto overflow-x-hidden p-4;
  }
}

.slip_container {
  @apply w-full h-full relative p-4 overflow-auto space-y-10;
  @screen lg {
    width: calc(100% - 320px);
    left: 320px;
  }

  .slip_print {
    @apply bg-white rounded-xl p-10 shadow-xl m-auto space-y-10;
    width: 800px;

    h1,
    h2 {
      @apply mb-2;
    }

    h1 {
      @apply text-2xl font-bold text-gray-text_dark;
    }

    h2,
    tr {
      @apply text-base font-bold text-gray-text_dark;
    }

    p,
    td {
      @apply text-sm text-gray-text_light font-normal;
    }

    table {
      @apply w-full text-right;

      th {
        padding-right: 24px;
        &:last-child {
          padding-right: 0px;
        }
      }

      td {
        @apply py-4;
        padding-right: 24px;
        &:last-child {
          padding-right: 0px;
        }
      }
    }
  }
}

// hover離開按鈕時顯示文字
.exitSpan {
  @apply border-r h-full pr-2 flex items-center transition-all  absolute transform translate-x-0 opacity-0 duration-200 ease-in-out text-sm;
}
.themeTitle {
  @apply text-gray-text_dark text-xl font-medium relative transition-transform  transform translate-x-0 duration-200 ease-in-out;
}

.exitBtn:hover ~ .exitSpan {
  @apply translate-x-10 opacity-100;
}
.exitBtn:hover ~ .themeTitle {
  @apply translate-x-14;
}

.checkbox_container {
  @apply w-full border rounded-xl transition-colors flex bg-white;
}
.checkbox_isShow_container {
  @apply border rounded-xl transition-colors flex bg-white;
}

::v-deep .vs-checkbox-label,
::v-deep .vs-radio__label {
  width: 100%;
  display: block;
  color: var(--gray-dark);
}

@media print {
  @page {
    margin: 0.5cm;
    size: A4 portrait;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    font-family: Arial, Helvetica, sans-serif;
  }

  .no_print {
    @apply hidden;
  }

  main {
    background: #fff;
  }
  .slip_container {
    overflow: visible;
  }

  .slip_print {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .no_break {
    break-inside: avoid;
  }
}
</style>
